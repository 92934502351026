<template>
<div class="fit sticky-form-actions edit-panel">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-btn no-caps dense color="danger" @click.prevent="showDeleteUserPanel(user)" icon="delete" :label="translate('delete')" flat />
                <toggle-active @closePanel="closePanel()" :is_active="user.is_active" :patch_toggle_route="patch_toggle_route" :item_id="user.id" :entity_type="entity_type" />
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item>
                    <q-item class="p-0">
                        <q-item-section avatar class="p-0">
                            <q-avatar class="ml-2 shadow-2">
                                <img v-if="user.image_link" class="black-and-white" :src="user.image_link">
                                <i v-else class="fas fa-user fa-1x text-primary"></i>
                            </q-avatar>
                        </q-item-section>
                        <q-item-section class="full-height align-self-center pt-2 pb-2">
                            <strong class="text-muted">{{ user.name }}</strong>
                        </q-item-section>
                    </q-item>
                    <q-item class="p-0">
                        <q-item-section avatar class="p-0">
                            <q-avatar class="ml-2 shadow-2">
                                <i class="fas fa-envelope fa-1x text-primary"></i>
                            </q-avatar>
                        </q-item-section>
                        <q-item-section class="full-height align-self-center pt-2 pb-2">
                            <strong class="text-muted">{{ user.email }}</strong>
                        </q-item-section>
                    </q-item>
                    <q-item class="p-0">
                        <q-item-section avatar class="p-0">
                            <q-avatar class="ml-2 shadow-2">
                                <i class="fas fa-user-cog fa-1x text-primary"></i>
                            </q-avatar>
                        </q-item-section>
                        <q-item-section class="full-height align-self-center pt-2 pb-2">
                            <strong class="text-muted">{{ translate(user.user_type) }}</strong>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side></q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import DeleteUser from '../forms/DeleteUser'
import ToggleActive from '../../../template-parts/ToggleActive'

export default {
    name: 'UserInfo',
    props: ['user', 'entity_type', 'patch_toggle_route'],
    components: {
        'toggle-active': ToggleActive,
    },
    data: function () {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0
        }
    },
    created: function () {
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        showDeleteUserPanel: function (user) {
            const panelInstance = this.$showPanel({
                component: DeleteUser,
                props: {
                    user: user
                }
            })

            panelInstance.promise
                .then(result => {
                    // Fix issue from slideout closing panel.
                    document.querySelector('body').classList.remove('slideout-panel-open')
                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    },
}
</script>

