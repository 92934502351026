<template>
<div>
    <b-card class="m-0" no-body>
        <div slot="header">
            <h3> {{translate('users')}}</h3>
        </div>
        <search-wrapper-card :active="user_active" :activeStatus="activeStatus" :inactive="user_inactive" :inactiveStatus="inactiveStatus" :typeDisable="false" form_type="users">
            <q-input slot="search_input" bottom-slots @input="isTyping = true" v-model="searchQuery" id="filternameuser" class="search-input" type="text" :placeholder="translate('find_user')" :dense="true">
                <template v-slot:prepend>
                    <q-icon name="search" />
                </template>
            </q-input>
        </search-wrapper-card>
    </b-card>
    <div class="list-group wrapper_users-list">
        <q-item v-for="(user,i) in users" :key="'user-' + i + '-' + user.id" :id="user.id" class="list-item full-width">
            <q-item-section avatar class="p-0">
                <q-avatar class="ml-2 shadow-2 cursor-pointer" @click.prevent="showUserInfoPanel(user)">
                    <img v-if="user.image_link" class="black-and-white" :src="user.image_link">
                    <i v-else class="fas fa-user fa-1x text-muted"></i>
                </q-avatar>
            </q-item-section>
            <q-item-section class="full-height align-self-center pt-2 pb-2">
                <q-item-label class="small text-muted">{{ translate(user.user_type) }}</q-item-label>
                <strong class="text-muted">{{ user.name }} &lt;{{ user.email }}&gt;</strong>
            </q-item-section>
            <!-- [+] Desktop sections. -->
            <template v-if="!$q.screen.lt.sm">
                <q-item-section side class="full-height align-self-center pt-2 pb-2">
                    <q-btn round icon="edit" color="primary" @click="showUserInfoPanel(user)">
                        <q-tooltip :offset="[10, 10]">{{ translate('edit_' + entity_type) }}</q-tooltip>
                    </q-btn>
                </q-item-section>
                <q-item-section side class="full-height align-self-center pt-2 pb-2">
                    <q-btn round icon="delete" color="danger" @click="showDeletePanel(user)">
                        <q-tooltip :offset="[10, 10]">{{ translate('delete_' + entity_type) }}</q-tooltip>
                    </q-btn>
                </q-item-section>
                <q-item-section side class="full-height align-self-center pt-2 pb-2">
                    <toggle-active :is_active="user.is_active" :no_label="true" :patch_toggle_route="patch_toggle_route" :item_id="user.id" :entity_type="entity_type" />
                </q-item-section>
            </template>
            <!-- [-] Desktop sections. -->
        </q-item>
    </div>
    <infinite-loading :identifier="'users-' + infUsers" slot="append" @infinite="getUsers" />
</div>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../../main'
import SearchWrapperCard from '../../template-parts/SearchWrapperCard'
import DeleteUser from './forms/DeleteUser'
import ToggleActive from '../../template-parts/ToggleActive'
import UserInfo from './show/UserInfo'

export default {
    name: 'Users',
    components: {
        'search-wrapper-card': SearchWrapperCard,
        'toggle-active': ToggleActive,
    },
    data: function () {
        return {
            infUsers: +new Date(),
            users: [],
            searchQuery: "",
            user_active: 'active',
            user_inactive: '',
            order_by: '',
            isTyping: false,
            show: true,
            page: 1,
            state: {},
        }
    },
    computed: {
        entity_type: function () {
            return 'user'
        },
        patch_toggle_route: function () {
            return baseUrl + 'users'
        },
    },
    mounted: function () {
        eventBus.$on('toggle_active_user', (item) => {
            this.toggleActiveUser(item)
        })
        eventBus.$on('delete_user', (id) => {
            this.deleteUser(id)
        })
    },
    watch: {
        searchQuery: _.debounce(function () {
            this.isTyping = false
        }, 200),
        isTyping: function (value) {
            if (!value) {
                this.resetMainList()
            }
        }
    },
    methods: {
        toggleActiveUser: function (item) {
            if (item.id !== null) {
                // Find index of specific object using findIndex method,
                var objIndex = this.users.findIndex((obj => obj.id === item.id))
                if (this.users[objIndex]) {
                    // And update is_active for the given item in list.
                    this.users[objIndex]['is_active'] = item.is_active ? true : false
                    eventBus.$emit('update_toggle_' + this.entity_type, item)

                    if (item.is_active === 0 && this.user_active === 'active' && this.user_inactive === '') {
                        this.deleteUser(item.id)
                    }
                    if (item.is_active === 1 && this.user_active === '' && this.user_inactive === 'inactive') {
                        this.deleteUser(item.id)
                    }
                }
            }
        },
        deleteUser: function (id) {
            // Find index of specific object using findIndex method,
            var objIndex = this.users.findIndex((obj => obj.id === id))
            // and remove the element from the users array.
            this.users.splice(objIndex, 1)
        },
        showUserInfoPanel: function (user) {
            const panelInstance = this.$showPanel({
                component: UserInfo,
                props: {
                    user: user,
                    entity_type: this.entity_type,
                    patch_toggle_route: this.patch_toggle_route
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel', {
                            status: true
                        })
                    }
                    // Fix issue from slideout closing panel.
                    document.querySelector('body').classList.remove('slideout-panel-open')
                })
        },
        resetMainList: function () {
            this.page = 1
            this.users = []
            this.infUsers++
        },
        activeStatus: _.debounce(function () {
            if (this.user_active === 'active') {
                this.user_active = ''
            } else {
                this.user_active = 'active'
            }
            this.resetMainList()
        }, 200),
        inactiveStatus: _.debounce(function () {
            if (this.user_inactive === 'inactive') {
                this.user_inactive = ''
            } else {
                this.user_inactive = 'inactive'
            }
            this.resetMainList()
        }, 200),
        patchActivate: function (id) {
            var status = this.users.map(parent2 => {
                let array = parent2
                return array.is_active
            })
            var active = status ? 1 : 0
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            var data = {
                'is_active': active,
            }
            axios.patch(baseUrl + 'users/' + id + '/is_active/' + active, data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    if (response.data.status) {
                        this.$toasted.success('Your status is updated', {
                            duration: this.$toasted_duration
                        })
                        this.resetMainList()
                    } else {
                        this.$toasted.error('The data was not updated', {
                            duration: this.$toasted_duration
                        })
                    }

                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        patchDeactivate: function (id) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            var status = this.users.map(parent2 => {
                let array = parent2
                return array.is_active
            })
            var active = status ? 0 : 1

            var data = {
                'is_active': active,
            }

            axios.patch(baseUrl + 'users/' + id + '/is_active/' + active, data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    if (response.data.status) {
                        this.$toasted.success('Your status is updated', {
                            duration: this.$toasted_duration
                        })
                        this.resetMainList()
                    } else {
                        this.$toasted.error('The data was not updated', {
                            duration: this.$toasted_duration
                        })
                    }

                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getUsers: function ($state) {
            var userUrl = baseUrl +
                'users?page=' + this.page +
                '&items_per_page=' + this.$items_per_page
            if (this.user_active === 'active' && this.user_inactive === '') {
                userUrl += '&user_status=' + this.user_active
            }
            if (this.user_active === '' && this.user_inactive === 'inactive') {
                userUrl += '&user_status=' + this.user_inactive
            }
            if (this.searchQuery) {
                userUrl += '&search=' + this.searchQuery
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            // To solve latency with server, push the items into a temporary scoped array and then into the main array.
            let users = this.users
            axios.get(userUrl, {
                headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        users.push(...response.data.items.map(entity => {
                            let array = entity
                            array['image_link'] = entity.image_link ? baseUrl + entity.image_link : null
                            array['user_id'] = !entity.user_id ? entity.id : entity.user_id
                            return array
                        }))
                    }
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                        this.users = users
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showDeletePanel(user) {
            const panelInstance = this.$showPanel({
                component: DeleteUser,
                props: {
                    user: user,
                }
            })

            panelInstance.promise
                .then(result => {
                    // Fix issue from slideout closing panel.
                    document.querySelector('body').classList.remove('slideout-panel-open')
                })
        },
    }
}
</script>

<style lang="scss" scoped>
.search-input {
    margin-left: -15px !important;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: 0 0 .5rem;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: border-color .1s ease-in-out;

    &:first-child {
        margin-top: .5rem;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    img.black-and-white {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
    }
    &:hover {
        border-color: rgba(0, 0, 0, .26);
        text-decoration: none;
        
        i {
            color: var(--q-color-negative)!important;
        }
        img.black-and-white {
            -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
            filter: grayscale(0);
        }
    }
}
</style>
